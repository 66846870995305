/* eslint-disable */
import { htmlSafe } from '@ember/string';
import { Promise as EmberPromise } from 'rsvp';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { A } from '@ember/array';
import EmberObject, { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import initController from '../../../mixins/init-controller';

import FormatUtil from 'tt4/utils/format';
const format = FormatUtil.create(); // because reasons

const { alias } = computed;

export default Controller.extend(initController, {
    store: service(),
    collector: service('collector-service'),
    intl: service(),
    capi: service(),
    ttapi: service(),
    dialogs: service(),
    gui: service(),
    session: service(),
    fileExports: service('file-exports'),
    flags: service(),
    evented: service(),
    router: service(),
    queryParams: ['date', 'page', 'user', 'employer'],
    date: null,
    page: 1,
    user: null,
    employer: null,
    limit: 25,
    historyDataLoading: false,
    historyFilters: null,
    newDeducterRecord: null,
    bankfields: null,

    worktimerows: alias('model.rows'),
    absences: alias('model.absences'),
    accounts: alias('model.accounts'),
    userRows: alias('model.users'),
    year: alias('model.year'),
    lastyear: alias('model.lastyear'),

    usersWithBanks: computed('model.{rows,column,absences}', 'deducters', 'rawRows', function () {
        return get(this, 'userRows.users').map(this.reformatUserRow.bind(this));
    }),

    columnList: computed('accounts', function () {
        const list = ['user'];

        if (
            get(this, 'collector').testNeedsOne('modules.holiday_pay') &&
            !this.flags.test('holidaypay_v2')
        ) {
            list.push('holiday_extra_pay');
        }

        get(this, 'accounts').forEach((account) => {
            let column = get(account, 'column');
            if (!column) {
                column = 'bank_' + get(account, 'id');
            }

            list.push(column);
        });

        return list;
    }),

    banks: computed('rows', function () {
        return get(this, 'store').peekAll('bank');
    }),

    translated_columns: computed('year', function () {
        return get(this, 'columnList').map((column) => {
            if (column === 'user') return get(this, 'intl').t('defaults.field.' + column);
            else if (column === 'holiday_extra_pay') {
                return get(this, 'intl').t('worktimebank.comp.holidays.extra_pay');
            } else {
                let account = get(this, 'accounts').findBy('column', column);
                if (!account) {
                    account = get(this, 'accounts').findBy('id', column.replace('bank_', ''));
                }
                return get(account, 'name');
            }
        });
    }),

    translated_columns2: computed('year', function () {
        return get(this, 'showHistoryColumns').map((column) => {
            if (column === 'date') {
                return get(this, 'intl').t('report.worktime.date');
            } else if (column === 'bank') {
                return get(this, 'intl').t('settings.timetracker.banks');
            } else if (column === 'absencetype') {
                return get(this, 'intl').t('abcense.abcensetype');
            } else if (column === 'description') {
                return get(this, 'intl').t('worktime.description');
            } else if (column === 'hours') {
                return get(this, 'intl').t('report.worktime.work_hours');
            } else if (column === 'addhours') {
                return get(this, 'intl').t('report.bankdeducter.addhours');
            } else {
                return column;
            }
        });
    }),

    filters: computed(function () {
        let dateValue;
        if (get(this, 'flags').test('bank-v2')) {
            dateValue = this.earliestDate + '_' + this.currentDate;
        } else {
            dateValue = moment().subtract(6, 'year').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD');
        }
        set(this, 'date', dateValue);
        let filters = A([
            EmberObject.create({
                field: 'date',
                type: 'date',
                form: 'worktime',
                value: get(this, 'date'),
                defaultValue: get(this, 'default_date'),
            }),
            EmberObject.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                value: get(this, 'user'),
            }),
            EmberObject.create({
                field: 'employer',
                type: 'database',
                form: 'worktime',
                value: get(this, 'employer'),
            }),
        ]);

        return filters;
    }),

    rows: computed('model.{rows,column,absences}', 'deducters', 'rawRows', function () {
        return ObjectProxy.extend(PromiseProxyMixin).create({
            //eslint-disable-line
            promise: new EmberPromise((resolve) => {
                const deducterPromise = get(this, 'deducters');
                const holidaysthisyearPromise = get(this, 'collector').testNeedsOne('abcense')
                    ? this.holidays_year(get(this, 'year'))
                    : [];
                const holidayslastyearPromise = get(this, 'collector').testNeedsOne('abcense')
                    ? this.holidays_year(get(this, 'lastyear'))
                    : [];

                EmberPromise.all([
                    deducterPromise,
                    holidaysthisyearPromise,
                    holidayslastyearPromise,
                ]).then(async (responses) => {
                    let data = get(this, 'rawRows')
                        ? await this.exportRows()
                        : get(this, 'worktimerows');

                    const removedUsers = await this.returnRemovedUsers();
                    const filteredValues = data.filter((rows) => {
                        return !removedUsers.includes(get(rows, 'user.id'));
                    });
                    const deducters = responses[0];
                    const holidays_this_year = responses[1];
                    const holidays_last_year = responses[2];
                    this.resolveRows(
                        deducters,
                        holidays_this_year,
                        holidays_last_year,
                        resolve,
                        filteredValues,
                    );
                });
            }),
        });
    }),

    deducters: computed('model.{rows,column}', 'rawRows', async function () {
        const exportRows = this.rawRows ? await this.exportRows() : null;
        const userids = exportRows
            ? exportRows.mapBy('user.id')
            : get(this, 'worktimerows').mapBy('user.id');
        return get(this, 'store').query('bankdeducter', {
            user: userids.join(','),
            groupBy: 'user,column,addhours',
            date: get(this, 'date'),
            limit: 'all',
        });
    }),

    pageCount: computed('worktimerows.meta.count', function () {
        let limit;
        limit = get(this, 'limit') ? get(this, 'limit') : 25;
        return Math.ceil(get(this, 'worktimerows.meta.count') / limit);
    }),

    isCordova: computed(function () {
        return get(this, 'gui.isCordova');
    }),

    earliestDate: computed('model.firstDate', function () {
        // If model.firstDate is empty or undefined, fallback to 6 years ago to match old logic, etc.
        return get(this, 'model.firstDate') || moment().subtract(6, 'years').format('YYYY-MM-DD');
    }),

    currentDate: computed(function () {
        return moment().format('YYYY-MM-DD');
    }),

    actions: {
        clearAllFilters() {
            // Reset the query param properties
            this.set('date', null);
            this.set('user', null);
            this.set('employer', null);
            this.set('page', 1);

            // Transition to the same route with an empty queryParams object
            this.transitionToRoute('timetracker.bank', { queryParams: {} });
          },
        deducterAdded(rec) {
            if (this.checkedRows) {
                let promises = [];
                for (let i = 1; i < this.checkedRows.length; i++) {
                    promises.push(
                        get(this, 'store')
                            .createRecord('bankdeducter', {
                                date: get(rec, 'date'),
                                user: this.checkedRows[i],
                                hours: get(rec, 'hours'),
                                column: rec.column,
                                addhours: get(rec, 'addhours'),
                                description: get(rec, 'description'),
                            })
                            .save(),
                    );
                }

                Promise.all(promises).then(() => {
                    set(this, 'checkedRows', false);
                    this.send('refreshReport');
                });
            } else {
                this.send('refreshReport');
            }
        },

        filterChanged(value, field) {
            // only way to empty date-filter
            if (field == 'date' && value == get(this, 'default_date')) {
                set(this, 'filters.0.value', null);
                this.notifyPropertyChange('filters');
            }
            set(this, field, value);
        },

        closeEditManualEntry() {
            set(this, 'showFormDeducter', null);
            this.send('refreshReport');
            this.send('showWorkBankHistoryModal', get(this, 'selectedUserObj'));
        },

        historyRowAction(action, row) {
            if (action === 'remove') {
                get(this, 'dialogs')
                    .confirm(get(this, 'intl').t('report.confirm_delete'))
                    .then(this.removeOrNot.bind(this, row));
            }
            if (action === 'edit') {
                let found = get(this, 'store').peekRecord('bankdeducter', row.id);
                if (found) {
                    set(this, 'showFormDeducter', found);
                } else {
                    get(this, 'store')
                        .peekRecord('bankdeducter', row.id)
                        .then((data) => set(this, 'showFormDeducter', data));
                }
            }
        },

        filterHistory(name, e) {
            this.filterHistory(name, e.target.value);
        },

        showDeducterModal(on) {
            if (this.banks.length === 0) {
                return get(this, 'dialogs').alert(
                    get(this, 'intl').t('worktimebank.notice.no_worktimebanks_found'),
                );
            }

            set(this, 'newDeducterRecord', this.store.createRecord('bankdeducter'));
            set(this, 'values', { user: null, hours: 0, addhours: false, description: null });
            set(this, 'showDeducter', on);
        },

        setBankDate(date) {
            set(this, 'values.date', date);
        },

        selectUser(record) {
            set(this, 'values.user', record);
        },

        setHours(hours) {
            set(this, 'values.hours', hours);
        },
        setAddhours(check) {
            set(this, 'values.addhours', check);
        },

        changeColumn(column) {
            set(this, 'values.column', column);
        },

        setDesc(desc) {
            set(this, 'values.description', desc);
        },

        setDays(days) {
            set(this, 'holidayValues.days', days);
        },

        setDescription(desc) {
            set(this, 'holidayValues.description', desc);
        },

        holidayPayAddModal(on) {
            set(this, 'holidayValues', { user: null, date: '', days: 0, description: null });
            set(this, 'holidayPayAdd', on);
        },

        //Holiday pay values

        selectHolidayUser(record) {
            set(this, 'holidayValues.user', record);
        },

        setDate(date) {
            set(this, 'holidayValues.date', date);
        },

        async showWorkBankHistoryModal(user) {
            const userid = user.userid;
            let absencetypes_ids = [];
            let banks = [];
            let historyData = [];

            set(this, 'historyDataLoading', true);
            set(this, 'historyData', null);
            set(this, 'selectedUser', null);
            set(this, 'historyData_filtered', null);
            set(this, 'historyFilters.type', 'both');
            set(this, 'historyFilters.bank', 'all');

            set(this, 'selectedUserObj', user);

            this.store
                .query('user', { id: userid })
                .then((data) => set(this, 'selectedUser', data.objectAt(0)));

            const getAbsences = async () => {
                const date = get(this, 'date')
                    ? get(this, 'date')
                    : moment().subtract(6, 'year').format('YYYY-MM-DD') +
                      '_' +
                      moment().format('YYYY-MM-DD');
                return get(this, 'capi')
                    .request(
                        '/abcenses?user=' +
                            userid +
                            '&startdate=' +
                            date +
                            '&limit=100&abcensetype=' +
                            absencetypes_ids.map((el) => el.id) +
                            '&sideload=true',
                    )
                    .then((data) => {
                        data.abcenses.forEach((row) => {
                            let bank = '';
                            let bankf = banks.filter(
                                (el) => parseInt(el.absencetype) === parseInt(row.abcensetype),
                            );

                            if (bankf.length) {
                                bank = bankf[0].name;
                            }

                            let absencetype = '';

                            let absencetype_f = absencetypes_ids.filter(
                                (el) => parseInt(el.id) === parseInt(row.abcensetype),
                            );
                            if (absencetype_f.length) {
                                absencetype = absencetype_f[0].name;
                                var abcenserow = {
                                    hours: format.hours(row.absence_hours),
                                    user: data.users[0].firstname + ' ' + data.users[0].lastname,
                                    date: row.startdate,
                                    description: row.description,
                                    bank: bank,
                                    absencetype: absencetype,
                                    absencetype_id: absencetype_f[0].id,
                                    type: 'absence',
                                    addhours: false,
                                };
                                historyData.push(abcenserow);
                            }
                        });
                    });
            };

            const getBankdeducter = async () => {
                return this.store.query('bankdeducter', { user: user.userid }).then((data) => {
                    data.map((historyrow) => {
                        historyData.push({
                            id: get(historyrow, 'id'),
                            date: get(historyrow, 'date'),
                            addhours: get(historyrow, 'addhours'),
                            user:
                                get(historyrow, 'user.firstname') +
                                ' ' +
                                get(historyrow, 'user.lastname'),
                            description: get(historyrow, 'description'),
                            hours: format.hours(get(historyrow, 'hours')),
                            bank: getBankName(banks, get(historyrow, 'column')),
                            type: 'manual',
                        });
                    });
                });
            };

            const getBankName = (ar, columnName) => {
                if (columnName === null) return '';

                let found = ar.filter((el) => el.column === columnName);

                // no bank with column
                if (!found.length) {
                    let name = columnName.replace('bank_', '');
                    found = ar.filter((el) => el.row_info === name);
                }

                if (found.length) {
                    found = found[0].name;
                } else {
                    found = '';
                }
                return found;
            };

            const getWorkTime = async (column) => {
                let queryObj = {
                    date: get(this, 'date')
                        ? get(this, 'date')
                        : moment().subtract(6, 'year').format('YYYY-MM-DD') +
                          '_' +
                          moment().format('YYYY-MM-DD'),
                    sideload: true,
                    user: user.userid,
                    limit: 'all',
                };

                // Make sure we only get work entries that
                // actually have something filled in
                queryObj[column] = '!(empty)';

                return this.store.query('worktime', queryObj).then((worktimerows) => {
                    this.accounts.forEach((el) => {
                        const accountcolumn = el.column;
                        if (!accountcolumn) return;
                        const bankName = getBankName(banks, column);
                        worktimerows.forEach((workrow) => {
                            let hoursInAccount = get(workrow, accountcolumn);
                            if (hoursInAccount && get(el, 'name') === bankName) {
                                var bankrow = {
                                    hours: format.hours(hoursInAccount),
                                    user:
                                        get(workrow, 'user.firstname') +
                                        ' ' +
                                        get(workrow, 'user.lastname'),
                                    date: get(workrow, 'date'),
                                    bank: bankName,
                                    type: 'entry',
                                    addhours: true,
                                };
                                historyData.push(bankrow);
                            }
                        });
                    });
                });
            };

            const getbanks = async () => {
                return this.store.query('bank', {}).then((data) => {
                    data.forEach((row) => {
                        // only push if get(row, "absencetype.id") is something
                        let id = get(row, 'absencetype.id');
                        let name = get(row, 'absencetype.name');
                        if (id !== undefined) {
                            absencetypes_ids.push({
                                id,
                                name,
                            });
                        }

                        banks.push(JSON.parse(JSON.stringify(row)));
                    });
                });
            };

            set(this, 'historyQueryParams', { user: user.userid });
            set(this, 'historyModal', true);

            try {
                await getbanks();
                await getBankdeducter();

                // We need make request for each bank, becaus we cant use
                // column_name=!(empty) with multiple values
                for (let i = 0; i < banks.length; i++) {
                    if (banks[i].column) {
                        await getWorkTime(banks[i].column);
                    }
                }

                await getAbsences();
            } catch (e) {
                let message = e.message || 'Server error';
                get(this, 'dialogs').alert(message);
            }

            set(this, 'historyDataLoading', false);
            // Sort by date
            set(
                this,
                'historyData',
                historyData.sort((el1, el2) => (el1.date < el2.date ? 1 : -1)),
            );

            set(this, 'historyData_filtered', get(this, 'historyData'));
            this.filterHistory('type', 'both');
        },

        action(action, row) {
            if (action === 'showHistory') {
                this.send('showWorkBankHistoryModal', row);
            }
            if (action === 'checkedRows') {
                let users = row.map((item) => get(this, 'store').peekRecord('user', item.userid));
                set(this, 'checkedRows', users);
            }
        },

        closeHistoryModal() {
            set(this, 'historyModal', false);
            set(this, 'needsUpdate', false);
            this.send('refreshModel');
        },

        refreshReport() {
            this.notifyPropertyChange('rows');
            this.notifyPropertyChange('deducters');
        },

        // Update report after saved page
        updateReport() {
            this.send('refreshModel');
            set(this, 'showDeducter', null);
        },

        showSettings() {
            let self = this;
            this.store
                .findAll('bank', { include: 'absencetype', reload: true })
                .then(function (data) {
                    set(self, 'settingsModel', data);
                    set(self, 'showSettingsDialog', true);
                });
        },
        saveHolidayPays() {
            let self = this;
            const promise = get(this, 'store').createRecord('holidaypay', {
                date: get(this, 'holidayValues.date'),
                user: get(this, 'holidayValues.user'),
                days: get(this, 'holidayValues.days'),
                description: get(this, 'holidayValues.description'),
            });
            promise.save().then(function () {
                self.send('refreshModel');
            });
            this.send('holidayPayAddModal', false);
        },
        deleteBank(row) {
            let self = this;
            get(this, 'store')
                .peekRecord('bank', row.id)
                .destroyRecord()
                .then(function () {
                    self.send('refreshModel');
                });
        },
        createNewBank(values) {
            let self = this;
            get(this, 'store')
                .createRecord('bank', values)
                .save()
                .then(function () {
                    self.send('refreshModel');
                });
        },

        editBank(row) {
            return get(this, 'store').findRecord('bank', row.id);
        },
        saveBank(record) {
            return record.save().then(() => {
                this.send('refreshModel');
            });
        },
        async exportExcel() {
            let excelHeaders = [];
            get(this, 'translated_columns').forEach((col, index) => {
                excelHeaders.pushObject({ name: col, key: get(this, 'columnList')[index] });
            });
            if (this.flags.test('bank-v2')) {
                const exportRows = await this.newExportRows();

                const rows = exportRows?.users.map((user) => {
                    let banks = user._banks;
                    banks['user'] = get(user, 'lastname') + ' ' + get(user, 'firstname');
                    return banks;
                });
                get(this, 'fileExports').createExcelFromJSON({ rows: rows, columns: excelHeaders });
            } else {
                set(this, 'rawRows', true);

                get(this, 'rows').then((data) => {
                    get(this, 'fileExports').createExcelFromJSON({
                        rows: data.value,
                        columns: excelHeaders,
                    });
                    set(this, 'rawRows', false);
                });
            }
        },
    },

    async init_functions() {
        this.evented.on('storeEvent', this, 'onStoreEvent');

        if (get(this, 'flags').test('bank-v2')) {
            set(this, 'default_date', this.earliestDate + '_' + this.currentDate);
        } else {
            set(
                this,
                'date',
                moment().subtract(6, 'year').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            );
            set(
                this,
                'default_date',
                moment().subtract(6, 'year').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
            );
        }

        this._super(...arguments);
        this.rowActions = [{ action: 'showHistory', icon: 'move move-Search' }];
        (this.queryParams = ['date', 'page', 'user', 'employer']),
            (this.historyRowActions = [
                { action: 'remove', icon: 'move move-Delete' },
                { action: 'edit', icon: 'move move-Pencil' },
            ]);

        set(this, 'showHistoryColumns', [
            'date',
            'bank',
            'absencetype',
            'description',
            'hours',
            'addhours',
        ]);

        set(this, 'historyFilters', {
            type: 'both',
            bank: 'all',
        });

        const banks = await this.store.findAll('bank');
        let options = [];
        banks.forEach((bank) => {
            options.push({ value: get(bank, 'name'), id: get(bank, 'column') });
        });
        this.bankfields = {
            name: 'Bank',
            options,
        };
    },

    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
    },

    onStoreEvent(params) {
        const updateOnForms = ['worktime', 'abcense'];
        if (params.action === 'insert' && updateOnForms.includes(params.type)) {
            if (this.router.currentRouteName == 'timetracker.bank') this.send('refreshModel');
        }
    },

    filterHistory(name, value) {
        this.historyFilters[name] = value;

        let historydata = get(this, 'historyData');

        // filter by type
        if (this.historyFilters.type !== 'both') {
            historydata = historydata.filter((el) => {
                let ret = true;
                if (this.historyFilters.type === 'automatic') {
                    ret = el.type === 'entry' || el.type === 'absence';
                }

                if (this.historyFilters.type === 'manual') {
                    ret = el.type === 'manual';
                }
                return ret;
            });
        }

        // filter by bank
        if (this.historyFilters.bank !== 'all') {
            historydata = historydata.filter((el) => {
                let ret = true;
                ret = el.bank === this.historyFilters.bank;

                if (
                    el.absencetype &&
                    this.checkIfAbsenceTypeBelongsToBank(
                        el.absencetype_id,
                        this.historyFilters.bank,
                    )
                ) {
                    ret = true;
                }
                return ret;
            });
        }

        // finally remove any empty banks
        historydata = historydata.filter((el) => !(el.bank === '' && !el.absencetype));

        set(this, 'historyData_filtered', historydata);
    },

    checkIfAbsenceTypeBelongsToBank(absensetype_id, bank) {
        let bankwithAbsenceType = this.banks.toArray().filter((el) => {
            return bank === get(el, 'name');
        });

        if (bankwithAbsenceType.length) {
            bankwithAbsenceType = bankwithAbsenceType[0];
            if (parseInt(get(bankwithAbsenceType, 'absencetype.id')) === parseInt(absensetype_id)) {
                return true;
            }
        }
        return false;
    },

    reformatUserRow(row) {
        const ret = {
            user: row.firstname
                ? row.firstname + ' ' + row.lastname
                : row.values.firstname + ' ' + row.values.lastname,
            userid: row.id,
        };
        const banks = Object.keys(row._banks);
        banks.forEach(
            (bank) => (ret[bank] = htmlSafe(this.formatHours(row._banks[bank] / 60).string)),
        );
        return ret;
    },

    removeOrNot(row, answer) {
        if (!answer) return;
        this.store.query('bankdeducter', { id: row.id }).then(this.removeRow.bind(this, row));
    },

    removeRow(row, rows) {
        get(rows, 'firstObject').destroyRecord();
        set(
            this,
            'historyData_filtered',
            get(this, 'historyData_filtered').filter((el) => el.id !== row.id),
        );
    },

    formatHours(hours) {
        if (hours === 0) return htmlSafe('<span class="move move-Time"></span> 0:00h');
        else return get(this, 'collector')._format_(hours, 'worktime', 'work_hours');
    },

    async returnRemovedUsers() {
        const removedUsers = await this.store.query('user', {
            row_info_status: 'removed',
            limit: 'all',
        });
        return removedUsers.map((row) => row.id);
    },

    async exportRows() {
        let params = {
            date: get(this, 'date'),
            user: get(this, 'user'),
            limit: 'all',
            sideload: true,
            groupBy: 'user',
            cols: get(this, 'worktimerows.query.cols'),
        };
        if (get(this, 'employer')) params.employer = get(this, 'employer'); // do not add employer if customer doesn't have employer module

        return await get(this, 'store').query('worktime', params);
    },

    async newExportRows() {
        const query = ['limit=all'];
        if (get(this, 'date')) query.push('date=' + get(this, 'date'));
        if (get(this, 'user')) query.push('id=' + get(this, 'user'));
        if (get(this, 'employer')) query.push('employer=' + get(this, 'employer'));

        let url = 'api/usersWithBanks' + (query.length ? '?' + query.join('&') : '');
        return await get(this, 'ttapi').request(url);
    },

    resolveRows(deducters, holidays_this_year, holidays_last_year, resolve, rows) {
        const values = rows.map((row) => {
            const compRow = {
                user: get(row, 'user.lastname') + ' ' + get(row, 'user.firstname'),
                userid: get(row, 'user.id'),
            };

            get(this, 'accounts').forEach((account) => {
                let column = get(account, 'column');

                if (!column) {
                    column = 'bank_' + get(account, 'id');
                }

                const deducter = deducters.find((item) => {
                    // var belongsToBank = true
                    if (get(item, 'user') === get(row, 'user') && get(item, 'column') === column) {
                        if (typeof get(item, 'addhours') === 'string') {
                            if (get(item, 'addhours').indexOf('off') >= 0) {
                                return true;
                            }
                        }

                        if (typeof get(item, 'addhours') === 'boolean') {
                            return get(item, 'addhours');
                        }
                    }

                    return false;
                });

                const adder = deducters.find((item) => {
                    if (get(item, 'user') === get(row, 'user') && get(item, 'column') === column) {
                        if (typeof get(item, 'addhours') === 'string') {
                            if (get(item, 'addhours').indexOf('on') >= 0) {
                                return true;
                            }
                        }

                        if (typeof get(item, 'addhours') === 'boolean') {
                            return get(item, 'addhours');
                        }
                    }
                    return false;
                });

                const deducted = deducter ? get(deducter, 'hours') : 0;

                const added = adder ? get(adder, 'hours') : 0;

                const abcenseRow = get(this, 'absences').find((item) => {
                    return (
                        get(item, 'user') === get(row, 'user') &&
                        get(item, 'abcensetype') === get(account, 'absencetype')
                    );
                });

                const abcense = abcenseRow ? get(abcenseRow, 'absence_hours') : 0;

                var banked = get(row, column) ? get(row, column) : 0;

                var total = banked + added - (deducted + abcense);
                //compRow[column] = deducted + "+" + abcense + "-" + banked + "(+" + added + ")"

                // file-exports needs to have unformatted data
                // export-action sets rawRows to true, then does exporting and sets rawsRows back to false
                if (get(this, 'rawRows')) {
                    compRow[column] = total;
                } else {
                    compRow[column] = htmlSafe(
                        "<span title='" +
                            (banked + added) +
                            ' - ' +
                            (deducted + abcense) +
                            "'>" +
                            this.formatHours(total).string +
                            '</span>',
                    );
                }

                //compRow[column] = htmlSafe(this.formatHours(deducted + abcense).string + " / " + this.formatHours(get(row, column) ? get(row, column) + added : 0).string)
            });

            if (get(this, 'collector').testNeedsOne('abcense')) {
                const temp_this_year = get(holidays_this_year, 'users').findBy(
                    'id',
                    parseInt(get(row, 'user.id'), 10),
                );
                compRow['holiday_thisyear'] = temp_this_year
                    ? get(temp_this_year, 'usedDays') + ' / ' + get(temp_this_year, 'allDays')
                    : '0 / 0';

                const temp_last_year = get(holidays_last_year, 'users').findBy(
                    'id',
                    parseInt(get(row, 'user.id'), 10),
                );
                compRow['holiday_lastyear'] = temp_last_year
                    ? get(temp_last_year, 'usedDays') + ' / ' + get(temp_last_year, 'allDays')
                    : '0 / 0';
                if (
                    get(this, 'collector').testNeedsOne('products.holiday_pay') &&
                    !this.flags.test('holidaypay_v2')
                ) {
                    compRow['holiday_extra_pay'] = temp_last_year
                        ? get(temp_last_year, 'holidayPays')
                        : 0;
                }
            }
            return compRow;
        });
        resolve({ value: values });
    },

    holidays_year(year) {
        const userids = get(this, 'worktimerows').mapBy('user.id');
        if (userids.length < 1) return null;
        let params = {
            start: year + '-05-02',
            country: get(get(this, 'store').peekAll('companyInfo'), 'firstObject.country'),
        };
        if (get(this, 'rawRows')) {
            params.limit = 'all';
        } else {
            params.id = userids.join(',');
        }

        const url = 'absence/getUsersWithHolidays';

        return get(this, 'collector').ttapi({
            url: url,
            data: params,
        });
    },

    //date: moment().startOf('isoweek').subtract(1, "week").format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD')
});
