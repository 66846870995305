import Controller from '@ember/controller';
import { set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
    store: service(),
    capi: service(),

    init() {
        this._super(...arguments);
        set(this, 'qParams', []);
        set(this, 'reportIsEmpty', true);
        set(this, 'loading', false);
        set(this, 'show_removed', true);
    },

    dummy: alias('model'),

    actions: {
        async setEmployer(employer) {
            set(this, 'employer', employer);
            this.filterReport();
        },
        async setUser(user) {
            try {
                if(user && user?.id) {
                    const userResult = await this.capi.findAll('users', { id: user.id });
                    set(this, 'user', userResult.content[0]);
                } else {
                    set(this, 'user', user);
                }
            } catch(err) {
                console.log("Unable to fetch user data", err);
                set(this, 'user', user);
            }
            this.filterReport();
        },

        showCard(row) {
            set(this, 'cardRow', row);
            set(this, 'showModal', true);
        },
    },
    async filterReport() {
        if (this.user?.id || this.employer?.id) {
            set(this, 'showReport', true);
            return;
        }
        set(this, 'showReport', false);
        return [];
    },
});
