/**
 * This service is used by gps and gps2 services to get the current position of the user.
 * It uses the browser's geolocation API to get the current position.
 * We need this service so that both gps and gps2 can access the current position at the same time
 * If this service is not used, gps and gps2 will request the position separately from the browser
 * and depending on the order how the services where initialized, either of the services may return timeout error
 * when requesting the position from the browser.
 */
import Service from '@ember/service';

export default class GeolocationService extends Service {
    watchId = null;
    currentPosition = null;
    callbacks = []; // List of success callbacks
    errorCallbacks = []; // List of error callbacks

    // Wrapper for getCurrentPosition
    getCurrentPosition(success, error, options = { enableHighAccuracy: true }) {
        if (this.currentPosition) {
            success(this.currentPosition);
        } else {
            // Call the native API once:
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.currentPosition = position;
                    success(position);
                },
                error,
                options,
            );
        }
    }

    // Wrapper for watchPosition
    watchPosition(success, error, options = { enableHighAccuracy: true }) {
        // Add these callbacks to lists so that every time we get a native update,
        // we notify all listeners.
        this.callbacks.push(success);
        this.errorCallbacks.push(error);

        // Only create a native watch if one isn’t already running
        if (this.watchId === null) {
            this.watchId = navigator.geolocation.watchPosition(
                (position) => {
                    this.currentPosition = position;
                    this.callbacks.forEach((cb) => cb(position));
                },
                (err) => {
                    this.errorCallbacks.forEach((errCb) => errCb(err));
                },
                options,
            );
        }
    }
}
